import React, { useEffect, useState } from "react"

import ContentModules from "../../components/Page/ContentModules"
import SEO from "../../components/seo"
import Product from "../../components/v2/product"
import { PageProps } from "gatsby"
import { ProductCategory } from "@medusajs/medusa"
import {
  ContentfulCollectionProduct,
  EnrichedMedusaProduct,
} from "../../../gatsby-node"

export interface ContentfulSizeGuide {
  title: string
  medusaCollectionHandles: string[]
  medusaProductTypes: string[]
  sizeGuides: {
    gender: string
    id: string
    title: string
    text: {
      childMarkdownRemark: unknown
    }
    body: {
      raw: string
      references: unknown[]
    }
  }[]
}

interface ProductPageTemplateProps {
  category: Pick<ProductCategory, "handle" | "id" | "name">
  collection: Pick<ProductCategory, "handle" | "id" | "name" | "metadata">
  product: EnrichedMedusaProduct
  size_guides: ContentfulSizeGuide[]
  type: string
  subType: string
  products: Pick<
    ContentfulCollectionProduct,
    | "color"
    | "id"
    | "handle"
    | "imagePacks"
    | "thumbnail"
    | "disableNotifyMe"
    | "sku"
    | "title"
    | "isGiftcard"
    | "variants"
  >[]
  shopstoryContent: unknown
  giftSetProducts?: Pick<
    EnrichedMedusaProduct,
    "id" | "imagePacks" | "disableNotifyMe" | "information"
  >[]
  seo: {
    breadcrumbsList: {
      "@type": string
      position: number
      name: string
      item: string
    }[]
  }
}

const ProductPageTemplate = ({
  pageContext,
  location,
}: PageProps<object, ProductPageTemplateProps>) => {
  const {
    category,
    collection,
    product,
    products,
    size_guides,
    giftSetProducts,
    seo,
  } = pageContext
  const preSelectedSize = location?.state?.size

  const handleContentModules = () => {
    if (!product.contentModules?.length) {
      return null
    }

    return (
      <div className="content-module">
        <ContentModules
          allModules={product.contentModules}
          shopstoryContent={pageContext.shopstoryContent}
        />
      </div>
    )
  }
  return (
    <>
      {product && (
        <Product
          category={category}
          sizeGuideGroups={size_guides}
          selectedColor={product}
          colorOptions={products}
          collection={collection}
          information={product?.information}
          seo={seo}
          contentModules={handleContentModules()}
          preselectedSize={preSelectedSize}
          giftSetProducts={giftSetProducts || []}
        />
      )}
    </>
  )
}

export default ProductPageTemplate
