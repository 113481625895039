// External packages
import * as React from "react"
import { Box, Flex, Paragraph, Image } from "theme-ui"

// Contexts
import { useStore } from "../../../context/NewStoreContext"

// Utilities
import { isProductOutOfStock } from "../utils/product/isProductOutOfStock"
import { getVariantsInventory } from "../utils/product/getVariantsInventory"
import { isVariantOutOfStock } from "../utils/product/isVariantOutOfStock"
import { handleAddToCart } from "../utils/product/handleAddToCart"

// Components
import { Drawer } from "../ui/Drawer"
import { Link } from "../Link"
import { ProductButton } from "./ProductButton"
import { Button } from "../Button"
import { SizePickerDrawer } from "./SizePickerDrawer"
import { NotifyMeDrawer } from "./NotifyMeDrawer"

// Types
import { ProductCategory } from "@medusajs/medusa/dist/models/product-category"
import { ProductType } from "@medusajs/medusa/dist/models/product-type"
import { PricedVariant } from "@medusajs/medusa/dist/types/pricing"

export type GiftSetProduct = {
  id: string
  title: string
  price: string
  image: any
  variants: PricedVariant[]
  disableNotifyMe: any
  url: string
  categories: ProductCategory[]
  primaryCategory: string
  size_guides?: any
  type: ProductType
  careInformation?: any
  detailsInformation?: any
}

export const GiftSetsDrawer = ({
  products,
  isGiftSetDrawerOpened,
  setIsGiftSetDrawerOpened,
  isGiftSetSizeDrawerOpened,
  setIsGiftSetSizeDrawerOpened,
  MedusaProductsLoaded,
  onAddToCart,
  setLocked,
}: {
  products: GiftSetProduct[]
  isGiftSetDrawerOpened: boolean
  setIsGiftSetDrawerOpened: React.Dispatch<React.SetStateAction<boolean>>
  isGiftSetSizeDrawerOpened: boolean
  setIsGiftSetSizeDrawerOpened: React.Dispatch<React.SetStateAction<boolean>>
  MedusaProductsLoaded: boolean
  onAddToCart: (value: string) => void
  setLocked: (value: boolean) => void
}) => {
  const { cart, createLineItem } = useStore()

  const [openedProduct, setOpenedProduct] = React.useState(null)
  const [isNotifyMeOpen, setIsNotifyMeOpen] = React.useState(false)
  const [selectedVariant, setSelectedVariant] = React.useState(null)

  return (
    <>
      <Drawer
        isOpened={isGiftSetDrawerOpened}
        hasBlurOnMobile={false}
        onCloseClick={() => {
          setIsGiftSetDrawerOpened(false)
          setLocked(false)
        }}
        onBackdropClick={() => {
          setIsGiftSetDrawerOpened(false)
          setLocked(false)
        }}
        onSwipeRight={() => {
          setIsGiftSetDrawerOpened(false)
          setLocked(false)
        }}
      >
        <Box
          sx={{
            ul: {
              listStyleType: "none",
              marginInline: 0,
              marginBlock: 6,
            },
            a: {
              color: "currentcolor",
            },
          }}
        >
          <Flex
            sx={{
              height: "100%",
              flexDirection: "column",
              alignItems: "center",
              gap: 4,
            }}
          >
            {products?.map(
              (product) =>
                !isProductOutOfStock(getVariantsInventory(product)) && (
                  <Flex
                    key={product?.id}
                    sx={{
                      alignItems: "center",
                      gap: [6, 10],
                      width: "100%",
                      maxWidth: [null, "364px"],
                      ".item-button": {
                        visibility: [null, "hidden"],
                        opacity: [null, 0],
                        transition: "visibility .2s, opacity .2s",
                      },
                      "&:hover .item-button": {
                        visibility: "visible",
                        opacity: 1,
                      },
                      ".item-content": {
                        transform: [null, "translateY(36px)"],
                        transition: "transform .2s",
                      },
                      "&:hover .item-content": {
                        transform: "translateY(0)",
                      },
                    }}
                  >
                    {product?.image ? (
                      <Link to={product?.url} sx={{ flexShrink: 0 }}>
                        <Box as="picture" sx={{ flexShrink: 0 }}>
                          {product?.image?.images.sources.map(
                            (source, index) => (
                              <source
                                key={index}
                                type={source.type}
                                srcSet={source.srcSet}
                                sizes={source.sizes}
                              />
                            )
                          )}
                          <Image
                            width={product?.image?.width}
                            height={product?.image?.height}
                            sizes={product?.image?.images.fallback.sizes}
                            decoding="async"
                            loading="lazy"
                            src={product?.image?.images.fallback.src}
                            srcSet={product?.image?.images.fallback.srcSet}
                            alt={product?.title}
                            sx={{
                              display: "block",
                              width: 35,
                              height: "100%",
                              objectFit: "cover",
                              transform: "translateZ(0)",
                            }}
                          />
                        </Box>
                      </Link>
                    ) : null}
                    <Flex
                      className="item-content"
                      sx={{
                        flexDirection: "column",
                        flexGrow: 1,
                        gap: 2,
                        fontSize: "sm",
                      }}
                    >
                      <Paragraph
                        sx={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        {product.title}
                      </Paragraph>
                      <Paragraph>{product.price}</Paragraph>
                      <Box sx={{ marginBlockStart: 4 }} className="item-button">
                        {product?.variants.length < 2 ? (
                          <ProductButton
                            sx={{ width: "100%", maxWidth: 45 }}
                            hasNotifyMe={false}
                            isProductSoldOut={
                              isProductOutOfStock(
                                getVariantsInventory(product)
                              ) && Boolean(product?.disableNotifyMe)
                            }
                            isProductOutOfStock={isProductOutOfStock(
                              getVariantsInventory(product)
                            )}
                            isVariantSelected={Boolean(
                              product?.variants[0]?.id
                            )}
                            isVariantOutOfStock={isVariantOutOfStock(
                              product?.variants[0]
                            )}
                            isLoading={
                              createLineItem.isLoading || !MedusaProductsLoaded
                            }
                            isVariantSoldOut={
                              Boolean(product?.disableNotifyMe) &&
                              isVariantOutOfStock(product?.variants[0])
                            }
                            sizePickerVariant="single"
                            isGiftcard={false}
                            isMobile={false}
                            isInDrawer={false}
                            isProductRelated={false}
                            isProductSingleSize={product?.variants?.length < 2}
                            onClick={() => {
                              if (
                                !Boolean(product?.disableNotifyMe) &&
                                isVariantOutOfStock(product?.variants[0])
                              ) {
                                return
                              }

                              if (
                                (isProductOutOfStock(
                                  getVariantsInventory(product)
                                ) &&
                                  Boolean(product?.disableNotifyMe)) ||
                                (Boolean(product?.disableNotifyMe) &&
                                  isVariantOutOfStock(product?.variants[0]))
                              ) {
                                return
                              }

                              setLocked(false)
                              setIsGiftSetDrawerOpened(null)

                              handleAddToCart({
                                product: {
                                  title: product.title,
                                  subcategory: product.primaryCategory,
                                },
                                variant: {
                                  ...product.variants[0],
                                  quantity: 1,
                                },
                                cart: cart,
                                createLineItem: createLineItem,
                                onAddToCart: onAddToCart,
                              })
                            }}
                          />
                        ) : (
                          <Button
                            variant="ghost"
                            sx={{ width: "100%", maxWidth: 45 }}
                            onClick={() => {
                              setIsGiftSetSizeDrawerOpened(true)
                              setOpenedProduct(product)
                              setLocked(true)
                            }}
                          >
                            Select size
                          </Button>
                        )}
                      </Box>
                    </Flex>
                  </Flex>
                )
            )}
          </Flex>
        </Box>
      </Drawer>
      {openedProduct && openedProduct?.variants.length ? (
        <SizePickerDrawer
          variants={openedProduct?.variants}
          product={openedProduct}
          drawerProps={{
            isOpened: isGiftSetSizeDrawerOpened,
            onCloseClick: () => setIsGiftSetSizeDrawerOpened(false),
            onBackdropClick: () => {
              setIsNotifyMeOpen(false)
              setIsGiftSetSizeDrawerOpened(false)
              setLocked(false)
            },
            onSwipeRight: () => setIsGiftSetSizeDrawerOpened(false),
            sx: {
              backgroundColor: "grayscale.white",
              zIndex: 10001,
            },
          }}
          onAddToCart={onAddToCart}
          onNotifyMeClick={(variant) => {
            setIsNotifyMeOpen(true)
            setSelectedVariant(variant)
            setLocked(true)
          }}
          onSizeGuideCloseClick={() => null}
        />
      ) : null}
      <NotifyMeDrawer
        isOpened={isNotifyMeOpen}
        isNotifyOpenedFromDrawer={true}
        selectedVariant={selectedVariant}
        onCloseClick={() => setIsNotifyMeOpen(false)}
        onBackdropClick={() => {
          setIsNotifyMeOpen(false)
          setIsGiftSetSizeDrawerOpened(false)
          setIsGiftSetDrawerOpened(false)
          setLocked(false)
        }}
        sx={{ zIndex: 10002 }}
      />
    </>
  )
}
